import {
  fetchAutocompleteSuggestions,
  fetchCurrentLocation,
  fetchGeoLocationByIp,
} from '@/features/shop/services/GeoLocation/fetchers'
import { type GeoCoordinates } from '@/features/shop/services/GeoLocation/types'
import { queryOptions, useQuery } from '@tanstack/react-query'

const ReverseGeocodeQuery = 'Reverse geocode'
export type ReverseGeocodeQueryKey = [
  typeof ReverseGeocodeQuery,
  GeoCoordinates
]
export const reverseGeocodeQueryOptions = (coordinates: GeoCoordinates) =>
  queryOptions({
    queryKey: [ReverseGeocodeQuery, coordinates],
    queryFn: fetchCurrentLocation,
  })

export type AutocompleteSuggestionsQueryKey = readonly [
  'Autocomplete suggestions',
  { query: string }
]
const autocompleteSuggestionsQueryOptions = (
  query: string,
  sessionToken: string
) =>
  queryOptions({
    queryKey: ['Autocomplete suggestions', { query }] as const,
    queryFn: (context) => fetchAutocompleteSuggestions(context, sessionToken),
  })

export const useQueryAutocompleteSuggestions = (
  query: string,
  sessionToken: string
) => {
  return useQuery({
    ...autocompleteSuggestionsQueryOptions(query, sessionToken),
    enabled: Boolean(query),
    staleTime: Infinity,
    placeholderData: (previousData) => previousData,
  })
}

const geoLocationByIpQueryOptions = (ip: string) =>
  queryOptions({
    queryKey: ['Geo location', ip] as const,
    queryFn: () => fetchGeoLocationByIp(ip),
  })

export const useQueryGeoLocationByIp = ({
  ip,
  enabled,
}: {
  ip: string
  enabled: boolean
}) => {
  return useQuery({
    ...geoLocationByIpQueryOptions(ip),
    enabled: Boolean(enabled),
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })
}
